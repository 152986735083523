.pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  &__pager {
    margin-left: 5px;
    margin-right: 5px;
    list-style: none;
    button {
      width: 80px;
      height: 35px;
      background: transparent;
      border: 1px solid #ccc;
      border-radius: 3px;
      font-size: 14px;
      color: #333;
    }
    &--number {
      button {
        width: 35px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    &--active {
      button {
        background-color: #e3e3e3;
        font-weight: 500;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    &__pager {
      &--number {
        display: none;
      }
    }
  }
}