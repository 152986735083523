.layout {
  background-color: #282c34;
  color: white;
  margin: 0 auto;
  padding: 10px;
  font-family: Arial;
  text-align: center;
  max-width: 50%;
  height: 50%;
  margin-top: 100px;
  box-sizing: border-box;
}

.layoutProduct {
  background-color: #282c34;
  color: white;
  margin: 0 auto;
  padding: 10px;
  font-family: Arial;
  text-align: left;
  max-width: 100%;
  height: 50%;
  margin-top: 100px;
  box-sizing: border-box;
}

label {
  display: inline-block;
  width: 150px;
  text-align: right;
}

div {
  margin-bottom: 10px;
}
