.layout {
  background-color: #282c34;
  color: white;
  margin: 0 auto;
  padding: 10px;
  font-family: Arial;
  text-align: center;
  max-width: 50%;
  height: 50%;
  margin-top: 100px;
  box-sizing: border-box;
}