header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  background-color: #fff;
  line-height: 50px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  z-index: 10;
  .logo {
    font-size: 18px;
    font-weight: 600;
    line-height: 1em;
    color:#fff;
    width:auto;
  }
}
