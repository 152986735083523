$ff-primary: 'Poppins', sans-serif;

$fw-extralight: 200;
$fw-light: 300;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;

@mixin mediaSm {
  @media screen and (min-width: 480px) {
    @content;
  }
}

@mixin mediaMd {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin mediaLg {
  @media screen and (min-width: 1224px) {
    @content;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $ff-primary;
  font-weight: $fw-light;
}

body {
  width: 100%;
}

// === RESET ===
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body,
html {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  width: 100%;
  display: block;
}

.main-title {
  font-size: 1.4em;
  font-weight: $fw-semibold;

  @include mediaMd {
    font-size: calc(.8em + 1.3vw);
  }
}

.main-info {
  font-size: .9em;

  @include mediaSm {
    font-size: 1em;
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity .3s ease-out;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity .3s ease-out;
}