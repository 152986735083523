footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: #eee;
  text-align: center;
  line-height: 50px;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.15);
  z-index: 10;
  .copyright {
    color: #555;
  }
}
