@import '../../index.scss';

.contact-container {
  width: 100%;
  max-width: 1200px;
  padding: 0 .5em;

  .title {
    font-size: 1.1em;
    margin-top: .8em;
  
    @include mediaMd {
      font-size: 1.2em;
    }
  }

  .contact {
    height: 350px;
    background-size: cover;
    background-position: center;
    color: #fff;
    padding: 1em;
    margin: 1.2em 0 1.8em 0;

    @include mediaMd {
      height: calc(400px + 16vw);
    }

    .title {
      font-size: 2.6em;
      text-transform: uppercase;
      font-weight: $fw-bold;
      margin-top: 1.6em;

      @include mediaMd {
        font-size: 3.6em;
      }
    }

    .subtitle {
      max-width: 370px;

      @include mediaMd {
        max-width: 450px;
      }
    }
  }
}


